<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card" :style="{
        width: '65vw',
      }">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t('DNS Settings') }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button class="delete" aria-label="close"  v-if="!isLoading" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">
          <div class="notification has-background-black-ter mt-4 has-text-white-ter" v-if="authorizedDomainsData.length === 0 && !isLoading">
            <span class="icon">
              <span class="fas fa-exclamation"></span>
            </span>
            <span class="has-text-weight-bolder">{{ t('You don\'t have any authorized/verified domain name.') }}</span>
          </div>
          <div class="columns is-multiline" v-if="authorizedDomainsData">
            <div class="column is-12" v-for="domain in authorizedDomainsData" :key="domain.domain">
              <div class="box is-shadowless">
                <h3 class="is-size-5">
                  <span>{{ domain.domain }}</span>

                </h3>
                <table class="table  is-bordered is-fullwidth is-narrow my-1">
                  <tr>
                    <td colspan="3">
                      <strong>
                        {{ t('Verification') }}
                        <span class="icon ml-1 is-size-6 has-text-danger" v-if="domain.verification.VerificationStatus === 'Failed'">
                          <span class="fas fa-times-circle"></span>
                        </span>
                        <span class="icon ml-1 is-size-6 has-text-danger" v-else-if="domain.verification.VerificationStatus === 'Pending'">
                          <span class="fas fa-clock"></span>
                        </span>
                        <span class="icon ml-1 is-size-6 has-text-success" v-else>
                          <span class="fas fa-check-circle"></span>
                        </span>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('Record Type') }}</th>
                    <th>{{ t('TXT Name') }}</th>
                    <th>{{ t('TXT Value') }}</th>
                  </tr>
                  <tr>
                    <td>TXT</td>
                    <td>{{ `_amazonses.${domain.domain}` }}</td>
                    <td>{{ domain.verification.VerificationToken }}</td>
                  </tr>
                </table>
                <hr class="p-0 m-0 my-2">

                <table class="table  is-bordered is-fullwidth is-narrow my-1">
                  <tr>
                    <td colspan="3">
                      <strong>
                        {{ t('DKIM') }}
                        <span class="icon ml-1 is-size-6 has-text-danger" v-if="domain.dkim.DkimVerificationStatus === 'Pending'">
                          <span class="fas fa-clock"></span>
                        </span>
                        <span class="icon ml-1 is-size-6 has-text-danger" v-else-if="domain.dkim.DkimVerificationStatus === 'Failed'">
                          <span class="fas fa-times-circle"></span>
                        </span>
                        <span class="icon ml-1 is-size-6 has-text-success" v-else>
                          <span class="fas fa-check-circle"></span>
                        </span>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('Type') }}</th>
                    <th>{{ t('Name') }}</th>
                    <th>{{ t('Value') }}</th>
                  </tr>
                  <tr v-for="token in domain.dkim.DkimTokens" :key="token">
                    <td>CNAME</td>
                    <td>{{ `${token}._domainkey.${domain.domain}` }}</td>
                    <td>{{ `${token}.dkim.amazonses.com` }}</td>
                  </tr>
                </table>

              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-9">
              <div class="field">
                <div class="control">
                  <input type="text" class="input" placeholder="mydomainaddress.com" v-model="authorizingDomain">
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="field">
                <div class="control">
                  <button class="button is-fullwidth" @click="send">
                    {{ t('Verify') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'DNSSettingsModal',
  data () {
    return {
      isOpen: false,
      isLoading: false,

      owner_identifier: null,

      authorizedDomainsData: [],
      authorizingDomain: '',

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },

  computed: {
  },
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.get();

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    async send() {
      const {
        owner_identifier
      } = this;
      try {
        let data = await this.$store.dispatch("flowKit/postCmtAuthorizedDomains", {
          owner_company: owner_identifier,
          authorized_domains: [
              this.authorizingDomain
          ].concat(this.authorizedDomainsData.map((item) => { return item.domain; })),
        });

        this.authorizingDomain = '';

        if (!data.success) {
          throw data;
        }

        this.get();

      } catch (error) {
        console.log(error);
      }
    },
    async get() {
      const {
        owner_identifier
      } = this;
      this.isLoading = true;
      try {
        let data = await this.$store.dispatch("flowKit/getCmtAuthorizedDomains", {
          owner_company: owner_identifier
        });

        if (!data.success) {
          throw data;
        }
        this.authorizedDomainsData = data.domains.domains || [];

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>
