<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card" style="width: 44vw;">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ t('Company Users') }}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">
          <div class="columns">
            <div class="column is-6">
              <div class="box is-shadowless">

                <h4 class="is-size-5 has-text-weight-bold">{{ t('Active users') }}</h4>

                <div class="box is-shadowless">

                  <p><strong>{{ t('Name:') }}</strong> {{ $store.state.me.user.contact.name }}</p>
                  <p><strong>{{ t('Email:') }}</strong> {{ $store.state.me.user.email }}</p>
                  <p><strong>{{ t('Role:') }}</strong> {{ $store.state.me.user.roles }}</p>
                  <button class="button is-small is-pulled-right">
                    <span>{{ t('Remove') }}</span>
                    <span class="icon">
                      <span class="fas fa-trash"></span>
                    </span>
                  </button>
                  <button class="button is-small is-pulled-right mr-3">
                    <span>{{ t('Edit') }}</span>
                    <span class="icon">
                      <span class="fas fa-user-edit"></span>
                    </span>
                  </button>
                </div>
                <div class="box is-shadowless">

                  <p><strong>{{ t('Name:') }}</strong> {{ $store.state.me.user.contact.name }}</p>
                  <p><strong>{{ t('Email:') }}</strong> {{ $store.state.me.user.email }}</p>
                  <p><strong>{{ t('Role:') }}</strong> {{ $store.state.me.user.roles }}</p>
                  <button class="button is-small is-pulled-right">
                    <span>{{ t('Remove') }}</span>
                    <span class="icon">
                      <span class="fas fa-trash"></span>
                    </span>
                  </button>
                  <button class="button is-small is-pulled-right mr-3">
                    <span>{{ t('Edit') }}</span>
                    <span class="icon">
                      <span class="fas fa-user-edit"></span>
                    </span>
                  </button>
                </div>

              </div>
            </div>
            <div class="column is-6">

              <div class="box is-shadowless">
                <h4 class="is-size-5 has-text-weight-bold">{{ t('Create a new user') }}</h4>

                <div class="field">
                  <div class="control">
                    <label for="name">{{ t('Name') }}</label>
                    <input type="text" class="input" name="name" id="name" placeholder="John Doe" v-model="newCompanyUser.username">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label for="email">{{ t('Email') }}</label>
                    <input type="email" class="input" name="email" id="email" placeholder="john@cmt.com" v-model="newCompanyUser.email">
                  </div>
                </div>
                <div class="field">
                  <label for="role">{{ t('Role') }}</label>
                  <div class="control select is-fullwidth">
                    <select name="role" id="role" class="" v-model="newCompanyUser.role">
                      <option value="">{{ t('Select a role') }}</option>
                      <option value="administrator">{{ t('Administrator') }}</option>
                      <option value="collector">{{ t('Collector') }}</option>
                    </select>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label for="email_me">
                    <input type="checkbox" class="checkbox" name="email_me" id="email_me" v-model="newCompanyUser.email_password">
                      {{ t('Email me the password') }}
                    </label>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button class="button is-fullwidth" @click="createUser">
                      {{ t('Create user') }}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <span class="is-clearfix"></span>

        </section>

        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'CompanyUsersModal',
  data () {
    return{
      isOpen: false,

      owner_identifier: null,

      newCompanyUser:{},

      settingsData: {},
      company_name: null,

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  computed: {
  },
  methods: {
    async createUser(){

      try {
        this.newCompanyUser['company_name'] = this.company_name

        let data = await this.$store.dispatch("organizationalKit/postUserData",
          this.newCompanyUser
        );

        if (!data.success) {
          throw data;
        }

        this.close();
      } catch (error) {
        console.log(error);
      }
    },
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.company_name = values.company.name;
      this.get();

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    async send() {
      const {
        owner_identifier,
        settingsData
      } = this;
      try {
        let data = await this.$store.dispatch("organizationalKit/getOwnedCompanyStakeholders", {
          company: owner_identifier,
          settings: settingsData
        });

        if (!data.success) {
          throw data;
        }

        this.close();
      } catch (error) {
        console.log(error);
      }
    },
    async get() {
      const {
        owner_identifier
      } = this;
      try {
        let data = await this.$store.dispatch("organizationalKit/getOwnedCompanySettings", {
          company: owner_identifier,
          settings: [
              "flow.kit.send.reminder.email.from",
              "email.from"
          ]
        });

        if (!data.success) {
          throw data;
        }
        this.settingsData = data.settings || [];

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
