<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ t('Manage Notification Emails') }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button class="delete" aria-label="close"  v-if="!isLoading" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <table class="custom__table mb-0">
            <thead>
            <tr>
              <th>
                {{ t('Setting') }}
              </th>
              <th>
                {{ t('Email') }}
              </th>
            </tr>
            </thead>
            <tbody v-if="!isLoading">
            <tr v-for="(value, setting) in settingsData" :key="setting">
              <td>
                {{ setting }}
              </td>
              <td>
                <div class="field">
                <div class="control">
                <input type="email" class="input is-small" style="width: 100%;" v-model="settingsData[setting]" >
                </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              :disabled="isLoading"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
          <span
              class="card-footer-item has-text-info is-clickable"
              :disabled="isLoading"
              @click="send"
          >
            {{ t('Confirm') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'NotificationEmailsModal',
  data () {
    return {
      isOpen: false,
      isLoading: false,
      owner_identifier: null,

      settingsData: {},

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  computed: {
  },
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.get();

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    async send() {
      const {
        owner_identifier,
        settingsData
      } = this;
      try {
        let data = await this.$store.dispatch("organizationalKit/putOwnedCompanySettings", {
          company: owner_identifier,
          settings: settingsData
        });

        if (!data.success) {
          throw data;
        }

        this.close();
      } catch (error) {
        console.log(error);
      }
    },
    async get() {
      const {
        owner_identifier
      } = this;

      this.isLoading = true;
      try {
        let data = await this.$store.dispatch("organizationalKit/getOwnedCompanySettings", {
          company: owner_identifier,
          settings: [
              "flow.kit.send.reminder.email.from",
              "email.from"
          ]
        });

        if (!data.success) {
          throw data;
        }
        this.settingsData = data.settings || [];

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>
