<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ t('Create a new company') }}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <div class="columns is-multiline px-4">
            <div class="column is-12">
              <div class="field">
                <div class="control">
                  <label for="company_name">{{ t('Company name') }}</label>
                  <input type="text" class="input" v-model="company.name" id="company_name"  :placeholder="t('My Company, B.V.')">
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <label>{{ t('Company Id') }}
                    <input type="text" class="input" v-model="company.owner_identifier" name="name" :placeholder="t('A:COMPANY#ID')">
                  </label>
                </div>
              </div>
            </div>
            <div class="column is-8">
              <div class="field">
                <div class="control">
                  <label for="company_vat">{{ t('Company VAT') }}</label>
                  <input type="text" class="input" v-model="company.vat" id="company_vat" placeholder="NL123456789B03">
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <div class="control">
                  <label for="address_line1">{{ t('Company Address') }}</label>
                  <input type="text" class="input" v-model="company.address.line1" id="address_line1" placeholder="">
                  <input type="text" class="input mt-1" v-model="company.address.line2" id="address_line2" placeholder="">
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <label for="postal_code">{{ t('Postal Code') }}</label>
                  <input type="text" class="input" v-model="company.address.postal_code" id="postal_code" name="name" placeholder="">
                </div>
              </div>
            </div>
            <div class="column is-8">
              <div class="field">
                <div class="control">
                  <label for="city">{{ t('City') }}</label>
                  <input type="text" class="input" v-model="company.address.city" id="city" placeholder="">
                </div>
              </div>
            </div>

            <div class="column is-12">
              <label for="country">{{ t('Country') }}</label>
              <div class="field">
                <div class="control select is-fullwidth">
                  <select name="country" id="country"  v-model="company.country">
                    <option value="">Select</option>
                    <option :value="country.iso2" :key="country.iso2" v-for="country in countriesData">
                      {{country.country}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <div class="control">
                  <label>
                    <span>{{ t('Company KVK') }}</span>
                    <input type="text" class="input" v-model="settingsData['company.kvk']" name="name" placeholder="134534222">
                  </label>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <div class="control">
                  <label>
                    <span>flow.kit.send.reminder.email.from</span>
                    <input type="text" class="input" v-model="settingsData['flow.kit.send.reminder.email.from']" name="name" placeholder="email@cmt.com">
                  </label>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <div class="control">
                  <label>
                    <span>{{ t('email.from') }}</span>
                    <input type="text" class="input" v-model="settingsData['email.from']" name="name" placeholder="email@cmt.com">
                  </label>
                </div>
              </div>
            </div>
          </div>
          <span class="is-clearfix"></span>

        </section>
        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
          <span
              class="card-footer-item has-text-info is-clickable"
              @click="send"
          >
            {{ t('Confirm') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'CreateCompanyModal',
  data (){
    return {
      isOpen: false,

      company: {
        owner_identifier: '',
        name: '',
        vat: '',
        country: '',
        address: {
          formatted_address: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: '',
          city: '',
        },

      },
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
      settingsData: {
        'company.kvk': '',
        'flow.kit.send.reminder.email.from': '',
        'email.from': '',
      },
      countriesData: [],
    };

  },

  computed: {

  },
  methods: {
    open(values) {
      console.log(values);

      this.getCountries();

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    async send() {
      let dataSettings = null;
      try {
        let data = await this.$store.dispatch("organizationalKit/putOwnedCompany", this.company);
        if(data.success) {
          dataSettings = await this.$store.dispatch("organizationalKit/putOwnedCompanySettings", {
            owner_company: data.company.owner_identifier,
            company: data.company.owner_identifier,
            settings: this.settingsData,
          });
        }

        if (!data.success || !dataSettings) {
          throw data;
        }

        // will dit work? NOp (just temporary)
        this.$store.state.me.user.companies.push(data.company);

        // TODO: @Daan -- here we need to re-request the user and update it because he has now new companies

        this.close();
      } catch (error) {
        console.log(error);
      }
    },
    async getCountries() {
      try {
        let data = await this.$store.dispatch("dataKit/getCountries", { });

        if (!data.success) {
          throw data;
        }
        this.countriesData = data.countries || [];

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
