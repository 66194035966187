<template>
  <div id="IssuesModal">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ t('Report an issue...') }}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">


                <iframe class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/24467122/f/qannj-121/4ERKQU48KLAYEW4D58"
                        onwheel="" width="100%" height="700px" style="background: transparent; border: none; "></iframe>


        </section>

        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'IssuesModal',
  data () {
    return{
      isOpen: false,

      owner_identifier: null,

      settingsData: {},

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  computed: {
  },
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
