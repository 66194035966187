<template>
  <div id="Settings">
    <ReminderFlowsModal ref="ReminderFlowsModal"/>
    <NotificationEmailsModal ref="NotificationEmailsModal"/>
    <CompanyUsersModal ref="CompanyUsersModal"/>
    <CreateCompanyModal ref="CreateCompanyModal"/>
    <DNSSettingsModal ref="DNSSettingsModal"/>
    <GeneralSettingsModal ref="GeneralSettingsModal"/>
    <import ref="ImportModal"/>
    <IssuesModal ref="IssuesModal"/>

    <div class="columns is-multiline px-3 pb-3">
      <div class="column is-6">
        <div class="box is-shadowless">
          <h3 class="is-size-5 has-text-weight-bold mb-2">
            <span>{{ t('User Settings') }}</span>
            <button class="button is-small is-pulled-right tooltip" disabled>
              <span>{{ t('Edit') }}</span>
              <span class="icon">
              <span class="fas fa-edit"></span>
            </span>
            </button>
          </h3>
          <p><strong>{{ t('Identifier:') }}</strong> {{ [
            $store.state.me.user.contact.profile_token.substr( 0, 4),
            $store.state.me.user.contact.profile_token.substr(4, 4),
            $store.state.me.user.contact.profile_token.substr(8, 4),
          ].join('-') + `-cm${$store.state.me.user.credentials}` }}</p>
          <p><strong>{{ t('Name:') }}</strong> {{ $store.state.me.user.contact.name }}</p>
          <p><strong>{{ t('Email') }}:</strong> {{ $store.state.me.user.email }}</p>
        </div>
        <div class="box is-shadowless">
          <h3 class="is-size-5 has-text-weight-bold mb-2">
            <span>{{ t('Experiencing issues?') }}</span>
          </h3>
          <button class="button is-default mt-2 has-text-weight-bold" @click="openIssuesModal">
            <span class="icon">
              <span class="fa fa-bug"></span>
            </span>
            <span>Click here to report an issue.</span>
          </button>
        </div>
      </div>
      <div class="column is-6">
        <div class="box is-shadowless">
          <h3 class="is-size-5 has-text-weight-bold mb-4">
            <span>{{ t('Company Settings') }}</span>
          </h3>
          <div class="box is-shadowless"
               v-for="company in $store.state.me.user.companies"
               :key="company">


            <button class="button is-small is-pulled-right has-text-weight-bold" :class="{

              }" @click="toggleSettings(company.identifier)">
              <span>{{ t('Settings') }}</span>
              <span class="icon">
                  <span class="fas fa-chevron-down" v-if="!(typeof expandedSettings[company.identifier] !== 'undefined' && expandedSettings[company.identifier])"></span>
                  <span class="fas fa-chevron-up" v-if="(typeof expandedSettings[company.identifier] !== 'undefined' && expandedSettings[company.identifier])"></span>
                </span>
            </button>

            <button class="button is-small is-pulled-right has-text-weight-bold mr-2" disabled>
              <span>{{ t('Edit') }}</span>
              <span class="icon"> <span class="fas fa-edit"></span></span>
            </button>

            <h4 class="is-size-5 has-text-weight-bold mb-2">
              <span class="is-block">{{ company.name }}</span>
            </h4>
            <p><strong>Identifier:</strong> {{ company.identifier }}</p>
            <p><strong>VAT:</strong> {{ company.vat }}</p>

            <template v-if="typeof expandedSettings[company.identifier] !== 'undefined' &&
                                   expandedSettings[company.identifier]">
              <p class="menu-label">SETTINGS</p>

              <button class="button is-small mt-4 is-block is-fullwidth has-text-left"
                      @click="openReminderFlowsModal(company)">
                <span class="icon"><span class="fas fa-list-ol"></span></span>
                <span>{{ t('Change Reminder Flows') }}</span>
              </button>
              <button class="button is-small mt-4 is-block is-fullwidth has-text-left"
                      @click="openNotificationEmailsModal(company)">
                <span class="icon"><span class="fas fa-mail-bulk"></span></span>
                <span>{{ t('Change Notification Emails') }}</span>
              </button>
              <button class="button is-small mt-4 is-block is-fullwidth has-text-left"
                      @click="openGeneralSettingsModal(company)">
                <span class="icon"><span class="fas fa-cogs"></span></span>
                <span>{{ t('Change General Settings') }}</span>
              </button>
              <button class="button is-small mt-4 is-block is-fullwidth has-text-left"
                      @click="openDNSSettingsModal(company)">
                <span class="icon"><span class="fas fa-network-wired"></span></span>
                <span>{{ t('DNS Settings') }}</span>
              </button>
              <button class=" button is-small mt-4 is-block is-fullwidth has-text-left tooltip"
                      @click="openCompanyUsersModal(company)">
                <span class="icon"><span class="fas fa-users-cog"></span></span>
                <span>{{ t('Manage Company Users') }}</span>
              </button>
              <button class="button is-small mt-4 is-block is-fullwidth has-text-left"
                      @click="openImportModal(company, 'organizational_company')">
            <span class="icon">
              <span class="fas fa-upload"></span>
            </span>
                <span>
              {{ t('Import Customers') }}
            </span>
              </button>
              <button class="button is-small mt-4 is-block is-fullwidth has-text-left"
                      @click="openImportModal(company, 'invoice_invoice')">
            <span class="icon">
              <span class="fas fa-upload"></span>
            </span>
                <span>
              {{ ('Import Customer\'s Invoices') }}
            </span>
              </button>
              <button class="button is-small mt-4 is-block is-fullwidth has-text-left tooltip" :disabled="true"
                      :data-tooltip="`In progress...`">
            <span class="icon">
              <span class="fas fa-upload"></span>
            </span>
                <span>
              {{ t('Import Bank Statements') }}
            </span>

              </button>

            </template>

          </div>

         <button class="button is-fullwidth" @click="openCreateCompanyModal">
           {{ t('Create a new company') }}
         </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ReminderFlowsModal from "@/views/Settings/Modals/ReminderFlows";
import NotificationEmailsModal from "@/views/Settings/Modals/NotificationEmails";
import CreateCompanyModal from "@/views/Settings/Modals/CreateCompany";
import CompanyUsersModal from "@/views/Settings/Modals/CompanyUsers";
import DNSSettingsModal from "@/views/Settings/Modals/DNSSettings";
import Import from "@/views/Settings/Modals/Import";
import GeneralSettingsModal from "@/views/Settings/Modals/GeneralSettings";
import IssuesModal from "@/views/Settings/Modals/IssuesModal";

export default {
  name: 'Settings',
  components: {
    IssuesModal,
    GeneralSettingsModal,
    Import,
    DNSSettingsModal, CompanyUsersModal, CreateCompanyModal, NotificationEmailsModal, ReminderFlowsModal
  },
  data() {
    return {
      expandedSettings: {},
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  methods: {
    toggleSettings(identifier) {
      if(typeof this.expandedSettings[identifier] === 'undefined') {
        this.expandedSettings[identifier] = true;
      } else { this.expandedSettings[identifier] = !this.expandedSettings[identifier]; }
    },
    openReminderFlowsModal(company) {
      const {
        identifier,
      } = company;
      this.$refs.ReminderFlowsModal.open({
        owner_identifier: identifier,
      });
    },
    openNotificationEmailsModal(company) {
      const {
        identifier,
      } = company;
      this.$refs.NotificationEmailsModal.open({
        owner_identifier: identifier,
      });
    },
    openGeneralSettingsModal(company) {
      const {
        identifier,
      } = company;
      this.$refs.GeneralSettingsModal.open({
        owner_identifier: identifier,
        parent_identifier: identifier,
      });
    },
    openCompanyUsersModal(company) {
      const {
        identifier,
      } = company;
      this.$refs.CompanyUsersModal.open({
        owner_identifier: identifier,
        company: company
      });
    },
    openCreateCompanyModal() {
      this.$refs.CreateCompanyModal.open({});
    },
    openIssuesModal() {
      this.$refs.IssuesModal.open({});
    },
    openDNSSettingsModal(company) {
      const {
        identifier,
      } = company;
      this.$refs.DNSSettingsModal.open({
        owner_identifier: identifier,
      });
    },
    openImportModal(company, objectType) {
      const {
        identifier,
      } = company;
      this.$refs.ImportModal.open({
        owner_identifier: identifier,
        objectType: objectType,
      });
    },
  }
};
</script>
